import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// reactstrap components
import { NavLink, Nav, Container, Row, Col } from "reactstrap";

const DefaultFooter = () => {
  return (
    <footer className="site-footer section bg-dark text-contrast top-left">
      <Container className="py-3">
        <Row className="row text-center text-md-left">
          <Col md="4" className="mr-auto">
            <img
              src={require("assets/img/aurlix-white.svg")}
              alt=""
              className="logo"
            />

            <p>
              Create, Build & Design Endless Possibilities.
            </p>
            <p className="mt-2 mb-0 copyright text-center text-md-left">
              © {new Date().getFullYear()}&nbsp;
              aurlix. All rights reserved.
            </p>
          </Col>

          <Col md="1">
            <Nav className="flex-column" tag="nav">
              <h6>Resources</h6>
              <NavLink className="py-2 text-contrast" href="#features">
                Features
              </NavLink>
              <NavLink className="py-2 text-contrast" href="#pricing">
                Pricing
              </NavLink>
              <NavLink className="py-2 text-contrast" href="">
                Contact Us
              </NavLink>
            </Nav>
          </Col>

          <Col md="1">
            <Nav className="flex-column" tag="nav">
              <h6>Learn</h6>
              <NavLink className="py-2 text-contrast" href={`https://www.youtube.com/channel/UCJ2E3A834yMJLMRF2E7254Q`}>
                Basics
              </NavLink>
            </Nav>
          </Col>

          <Col md="1">
            <Nav className="flex-column" tag="nav">
              <h6>Software</h6>
              <NavLink className="nav-item py-2 text-contrast" href={`https://${window.location.host}/signup`}>
                Join Us
              </NavLink>
              <NavLink className="nav-item py-2 text-contrast" href={`https://${window.location.host}/login`}>
                Login
              </NavLink>
            </Nav>
          </Col>

          <Col md="1">
            <h6 id="contact">Social</h6>

            <Nav className="flex-column" tag="nav">

              <NavLink className="nav-item py-2 text-contrast" target="_blank" href={`https://www.facebook.com/aurlix`}>
                <FontAwesomeIcon icon={["fab", "facebook-square"]} /> Facebook
              </NavLink>
              <NavLink className="nav-item py-2 text-contrast" target="_blank" href={`https://twitter.com/officialaurlix`}>
                <FontAwesomeIcon icon={["fab", "twitter"]} /> Twitter
              </NavLink>
              <NavLink className="nav-item py-2 text-contrast" target="_blank" href={`https://www.youtube.com/channel/UCJ2E3A834yMJLMRF2E7254Q`}>
                <FontAwesomeIcon icon={["fab", "youtube"]} /> YouTube
              </NavLink>
              <NavLink className="nav-item py-2 text-contrast" target="_blank" href={``}>
                <FontAwesomeIcon icon={["fab", "discord"]} /> Discord
              </NavLink>
            </Nav>
          </Col>
        </Row>

        <Row className="small">
          <Col md="12">
            <p className="mt-2 mb-0 text-center text-md-right">
              <a className="buffer" href={`https://${window.location.host}/tos`}>
                Terms of Service
              </a>
              <a className="buffer" href={`https://${window.location.host}/privacy`}>
                Privacy Policy
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default DefaultFooter;
