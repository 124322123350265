import React from "react";
import { Container, Row, Col, Nav, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Heading = () => {
  return (
    <header className="header alter3-header section gradient gradient-primary-auxiliary text-contrast">
      <Container className="bring-to-front">
        <Row>
          <Col md="6" className="title-placement order-lg-first">
            <h1 className="display-md-3 font-md">
              <b>Unlimited <span className="aurlix-color">possibilities</span>. Be yourself.</b>
            </h1>
            <p className="lead text-muted">
              A trading framework that's built from the ground up for rapidly moving markets. <br></br>Create, Build & Design Endless Possibilities.
            </p>

            <Nav className="mt-5" tag="nav">
              <NavLink
                href={`https://www.app.aurlix.com/signup`}
                className="mr-3 btn btn btn-rounded btn-colored"
              >
               Start now <FontAwesomeIcon
               icon={["fas", "chevron-right"]}
               className="icon fa-1x"
             />
              </NavLink>
            </Nav>
          </Col>
          <Col lg="6">
              <div className="monitor">
                <img
                  className="over"
                  src={require("assets/img/screens/computer/macbook-pro.png")}
                  alt="..."
                />
                <img
                  className="under"
                  src={require("assets/img/screens/computer/example.png")}
                  alt="..."
                />
              </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;
