import React from "react";
import { Container, Row, Col } from "reactstrap";

const renderFeatures = () => {
  let features = [];
  const elements = [
    {
      icon: "pe-7s-light",
      title: "Innovative",
      description:
        "Beautiful panels that are easy-to-use, create and build a trading worksheet perfectly suited to your trading style."
    },
    {
      icon: "pe-7s-tools",
      title: "Professional",
      description:
        "Built for professional traders, with customisable layouts, unlimited indicators and advanced charting tools."
    },
    {
      icon: "pe-7s-graph2",
      title: "Seamless",
      description:
        "Direct integrated access to over 120+ exchanges, discover thousands of assets across multiple categories."
    }
  ];

  elements.forEach((element, i) => {
    features.push(
      <Col md="4" className="py-4 features-box rounded shadow-hover">
        <i className={`pe ${element.icon} pe-3x mb-3 text-primary`} />
        <h5 className="bold">{element.title}</h5>
        <p>{element.description}</p>
      </Col>
    );
  });

  return features;
};

const Features = () => {
  return (
    <section id="features" className="section features inner-container">
      <Container>
        <div className="section-heading">
          <h2 className="heading-line">Professional tools tailored to your needs</h2>
          <p className="lead text-muted">
            Enhance and sharpen your strategy with aurlix. Everything from realtime charts, scanners, news sentiment to advanced orders all on one platform. Without limitations.
          </p>
        </div>

        <Row className="gap-y text-center text-md-left">{renderFeatures()}</Row>
      </Container>
    </section>
  );
};

export default Features;
