import React, { } from "react";
import { Container, Row } from "reactstrap";
import "./fontawesome-pro/js/all";
import PricingPlan from "./PricingPlan.jsx";

const plans = [
  {
    name: "Starter",
    desc: "Ideal for testing the aurlix platform",
    class: "order-md-first",
    price: "1",
    value: "/day"
  },
  {
    name: "Max Savings",
    desc: "Perfect for those who want maximal savings",
    class: "order-md-last",
    price: "189.99",
    value: "/yr"
  },
  {
    name: "Pro Trader",
    desc: "Take your trading to the next level now",
    price: "19.99",
    value: "/mo",
    best: true
  }
];

const Pricing = props => {


  return (
    <section className="section pricing-plans inner-container">
      <Container id="pricing">
        <div className="section-heading">
          <div className="section-heading">
            <h3 className="pricing-title">Ready to start with <span class="aurlix-color"> your best?</span></h3>
            <p className="text-muted lead mx-auto">
              Our plans have everything you want to elevate your trading game. Take the next step today.
            </p>
          </div>
        </div>

        <Row className="no-gutters align-items-center pricing-plans-options">
          {plans.map((plan, i) => (
            <PricingPlan {...plan} key={i} />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Pricing;
