import React from "react";
import { Container } from "reactstrap";

const Features = () => {
  return (
    <section id="features" className="section features inner-container">
      <Container className="about-container">
        <div className="section-heading">
          <h2 className="heading-line">Built for speed, flexibility and performance.</h2>
          <p className="lead text-muted">
            State-of-the-art trading framework for digital assets. Unlimited professional indicators, workspaces and widgets.
          </p>
          <div className="browser about-img shadow-lg">
            <img
              src={require("assets/img/screens/computer/feature_stack.png")}
              alt="..."
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Features;
