import React from "react";

const WhyUs = () => {
  return (
    <section className="section why-choose-us container inner-container">

      <div className="">
        <div className="section-heading">
          <h3 className="why-line text-muted">Why aurlix ?</h3>
          <h5>Reduce time switching multiple exchanges, focus on what you do best</h5>
          <p className="text-muted lead">
            Designed with powerful multi-threading and a flexible interface for rapidly moving digital markets. Anywhere, any operating system.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
