import React from "react";
import { Container, Row, Col } from "reactstrap";

const MultipleDesignBlocks = () => {

  return (
    <section className="section powered-design">
      <div className="shapes-container">

      </div>

      <Container className="inner-container">
        <Row>
          <Col lg="5" className="order-lg-first">
            <div className="section-heading spacing">
              <h3 className="test">
                Build awesome layouts.
              </h3>
              <p className="lead text-muted">
              Multiple <b>Workspaces</b> makes aurlix easy as possible to operate. Each component is <b>flexible</b> to move around or resize. Link and organize them together by <b>color</b>. Customizable and <b>blazing fast</b>.
              </p>
            </div>
          </Col>

          <Col lg="7" className="pr-lg-6">
              <div className="browser shadow-lg">
                <img
                  src={require("assets/img/screens/computer/widgets.png")}
                  alt="..."
                />
              </div>
          </Col>
        </Row>
        <Row className="order-lg-second">
          <Col lg="5">
            <div className="section-heading spacing">
              <h3 className="test">
                Advanced order management.
              </h3>
              <p className="lead text-muted">
                Accomplish <b>anything</b>. Multiple order types with server-side and native orders types. Achieve <b>greater</b> control, flexibility and <b>customisation</b>. Create custom execution <b>strategies</b> from charts.
              </p>
            </div>
          </Col>

          <Col lg="7" className="pr-lg-6">
              <div className="browser shadow-lg">
                <img
                  src={require("assets/img/screens/computer/widgetsTwo.png")}
                  alt="..."
                />
              </div>
          </Col>
        </Row>
        <Row className="order-lg-second">
          <Col lg="5">
            <div className="section-heading spacing">
              <h3 className="test">
                Built for action.
              </h3>
              <p className="lead text-muted">
                 Everything <b>counts</b>. Advanced charting engine, <b>fast</b> interface, a large selection of <b>professional-grade</b> analytics, charts, scanners, news sentiment and multiple trade <b>execution</b> interfaces all in one place.
              </p>
            </div>
          </Col>

          <Col lg="7" className="pr-lg-6">
              <div className="browser shadow-lg">
                <img
                  src={require("assets/img/screens/computer/widgetsThree.png")}
                  alt="..."
                />
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MultipleDesignBlocks;
