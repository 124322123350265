import React from "react";

// shared page components
import MultipleDesignBlocks from "../shared/MultipleDesignBlocks.jsx";
import WhyUs from "../shared/WhyUs.jsx";
import Pricing from "../shared/Pricing.jsx";

import Features from "../alter3/Features.jsx";
import About from "../alter3/About.jsx";
// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";

// alternative 3 page components
import Heading from "../alter3/Heading.jsx";
import Partners from "../alter3/Partners.jsx";

class Alternative3 extends React.Component {
  render() {
    return (
      <>
        <DefaultNavbar />
        <main ref="main">
          {/* Alternative 3 specific components */}
          <Heading />
	        <Partners />
          {/* Shared Components */}
          <About />
          <WhyUs />
          <MultipleDesignBlocks />
          <Pricing />
          <Features />
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default Alternative3;
