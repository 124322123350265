import React from "react";
import { Row, Col } from "reactstrap";
import Swiper from "react-id-swiper";
import "swiper/dist/css/swiper.css";

const params = {
  loop: true,
  autoplay: {
    delay: 2500,
    disableOnIteration: false
  },
  slidesPerView: 12,
  spaceBetween: 10
};

const Partners = () => {
  return (
    <section class="section">
      <div class="container exchanges py-5">
      <Row>
        <Col className="text-center">
          <div>
            <p className="lead">
              With over <b>120+ exchanges</b> plus <b>real-time data</b>, charting, analytics and <b>streaming news sentiment</b>.
            </p>

          </div>

        </Col>
      </Row>
        <Swiper {...params}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(i => (
            <div class="swiper-slide">
              <img
                src={require(`assets/img/logos/${i}.jpg`)}
                class="img-responsive"
                alt=""
                style={{ maxHeight: "60px" }}
              />
            </div>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Partners;
